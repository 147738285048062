import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby';

import Layout from '../components/layout'

const Terms = () => ( <Layout>
  <div>
    <Helmet title={'temtum & Wallet Terms | temtum'} meta={[{
          name: 'description',
          content: 'our use of the Website is subject to these website terms of use (Terms). Please read them carefully before using the Website. If you do not agree to these Terms, please do not use the Website.'
        }
      ]}/>
    <div className="hd-space"/>
    <section className="skipScroll py-3 border-top bg-blue-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-pills nav-fillX">
              <li className="nav-item">
                <Link className="nav-link " to="/terms">
                  Website Terms
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/wallet-terms">
                  temtum & Wallet Terms
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="/privacy">
                  Privacy Notice
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="text-white skipScroll bg2 pad50">
      <div className="blueblue video-overlay"/>
      <div className="wow3 video-overlay"/>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="display-4 font-weight-normalX">
              temtum & Wallet Terms
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section className="">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-9 col-lg-9 mx-auto">
            <div className="text-content">

              <h4>TEMTUM &amp; WALLET TERMS</h4>

              <p>In these terms (<strong>Terms</strong>), <strong>we/our/us</strong> means Great Harbour Trading Limited (which is incorporated in the British Virgin Islands and trades under the name Temtum, but is not referred to as Temtum in these Terms), which is the issuer of the cryptocurrency "<strong>Temtum</strong>", and <strong>you/your</strong> means you as a purchaser of Temtum from us or as user of a "<strong>Wallet</strong>" (when used in these Terms, Wallet means a login/user account set up with us that is used to hold, transfer and receive Temtum).</p>

              <ol className="nested-counters list-inside">
                <li>
                  <h4>FOREWORD</h4>

                  <p>PRIOR TO PURCHASING TEMTUM FROM US OR SETTING UP A WALLET, WE HIGHLIGHT FIVE IMPORTANT ITEMS TO YOU THAT YOU SHOULD BE AWARE OF:</p>
                  <ul>
                    <li>WE DO NOT GIVE ANY SPECIFIC COMMITMENTS AS TO THE AVAILABILITY OF YOUR WALLET OR THE TEMTUM NETWORK;</li>
                    <li>WE CANNOT GUARANTEE THAT YOUR WALLET OR THE TEMTUM NETWORK WILL NOT BE SUBJECT TO INTERFERENCE OR ATTACKS FROM HACKERS OR OTHER MALICIOUS ACTORS;</li>
                    <li>
                      THE PURCHASE OF TEMTUM BY, OR SALE OF TEMTUM TO, CERTAIN PERSONS MAY BE RESTRICTED BY LAW OR SUBJECT TO SPECIFIC REQUIREMENTS IN CERTAIN JURISDICTIONS. YOU SHOULD SEEK YOUR OWN LEGAL ADVICE IN CONNECTION WITH ANY PURCHASE OR SALE OF TEMTUM AND YOU SHOULD ONLY PROCEED WITH THE SET-UP OF A WALLET AND/OR PURCHASE OF TEMTUM IF:
                      <ol type="a">
                        <li>you are RESIDENT, LOCATED AND ESTABLISHED IN a jurisdiction in which the sale and purchase of cryptocurrency is legal;</li>
                        <li>you are able to access your wallet from a jurisdiction that does not prohibit the use of cryptocurrency;</li>
                        <li>you are able and willing to provide information AND/OR UNDERTAKINGS from time to time THAT WE CONSIDER NECESSARY OR DESIRABLE, FOR EXAMPLE personal and other relevant information for identity and other checks (which may incluDe further information from time to time on an ongoing basis to comply with relevant laws and regulatory requirements), OR AGREEMENT TO COMPLY WITH JURISDICTION SPECIFIC REQUIREMENTS.</li>
                      </ol>
                    </li>
                    <li>
                      THE ONLY RIGHT ASSOCIATED WITH TEMTUM IS THAT IT IS A CRYPTOCURRENCY OR DIGITAL COIN THAT CAN BE TRANSFERRED BETWEEN WALLET HOLDERS. OWNERSHIP OF TEMTUM CARRIES NO FURTHER RIGHTS OR FUNCTIONALITY. BY WAY OF EXAMPLE, TEMTUM DOES NOT:
                      <ol type="a">
                        <li>carry any rights that would be similar to a share or security e.g. a right to receive a portion of our profits or a right to certain assets;</li>
                        <li>grant any rights in respect of our property e.g. intellectual property rights in Temtum code;</li>
                        <li>result in any debt being owed by us to you;</li>
                        <li>enable participation in, or the receipt of profits or income from, property of any description;</li>
                        <li>have any GUARANTEEd MINIMUM VALUE; or</li>
                        <li>carry a right to be redeemed or exchanged for any particular goods or services (although we may freely decide from time to time at our discretion to accept payment in Temtum for good or services).</li>
                      </ol>
                    </li>
                    <li> WE CANNOT GUARANTEE THAT YOU WILL BE ABLE TO RECOVER ANY TEMTUM THAT YOU ERRONEOUSLY TRANSFER TO ANOTHER WALLET HOLDER OR THAT HAS BEEN TRANSFERRED WITHOUT YOUR AUTHORISATION.</li>
                  </ul>

                  <p>Please refer to clause 10 (WHAT YOU SHOULD BE AWARE OF) and Schedule 2 for further explanation regarding these items.</p>
                </li>

                <li>
                  <h4>These terms</h4>

                  <ol className="nested-counters list-inside">
                    <li>
                      <strong>What these terms cover</strong>. These are the terms on which we, as applicable:

                      <ol className="nested-counters">
                        <li>provide you with access to a Wallet to hold, receive or transfer Temtum (please see in particular clauses 6 and 7);</li>
                        <li>sell Temtum to you (please see in particular clauses 8 and 9).</li>
                      </ol>

                      Temtum is more fully described in the white paper published by us (as updated or modified from time to time, the <strong>White Paper</strong>), which can be found on our website at <a href="https://temtum.com">https://temtum.com</a> (as updated or modified from time to time, the <strong>Website</strong>). Please also refer to Schedule 1 (Useful Information) below for more information regarding our offering.
                    </li>

                    <li><strong>Why you should read them</strong>. Please read these Terms carefully before you set up a Wallet or purchase Temtum from us. These Terms tell you who we are, what you are buying, how we will provide Temtum to you, how you can use and access a Wallet, who to contact if there is a problem and other important information. By purchasing Temtum from us and/or using a Wallet, you will be bound by these Terms and any terms incorporated by reference. If you do not agree to these terms, or any part, please do not proceed with your set-up of a wallet or purchase of Temtum.</li>
                    <li>
                      <strong>Are you a business customer or a consumer?</strong> In some areas you will have different rights under these Terms depending on whether you are a business or consumer. You are a consumer if:
                      <ol className="nested-counters">
                        <li>You are an individual; and</li>
                        <li>You are buying Temtum from us wholly or mainly for your personal use (not for use in connection with your trade, business, craft or profession).</li>
                      </ol>

                      <p>Provisions specific to consumers only are in 10.11, 17, 19 and 22.6 and those specific to businesses only are in 2.6, 9.3, 18, 20 and 22.7.</p>
                    </li>

                    <li><strong>When are you purchasing Temtum? </strong>Depending on whether you are purchasing Temtum prior to, or during, or after the launch of the Temtum network (<strong>Launch</strong>), some of the terms that apply to your purchase will vary as set out in clauses 8 and 9 of these Terms.</li>
                    <li><strong>Scope of Terms. </strong>The purchase of Temtum and/or use of a Wallet by you will be governed by these Terms as updated or modified from time to time.</li>
                    <li><strong>If you are a business customer this is our entire agreement with you</strong>. If you are a business customer these Terms as updated or modified from time to time constitute the entire agreement between us in relation to your purchase of Temtum and/or use of a Wallet. You acknowledge that you have not relied on any statement, promise, representation, assurance or warranty made or given by or on behalf of us which is not set out in these Terms and that you shall have no claim for innocent or negligent misrepresentation or misstatement based on any statement in this agreement.</li>
                  </ol>
                </li>
                <li>
                  <h4>Information about us and how to contact us</h4>

                  <ol className="nested-counters list-inside">
                    <li><strong>Who we are</strong>. We are Great Harbour Trading Limited a company registered in the British Virgin Islands. Our company registration number is 1990517 and our registered office is at 1/F Columbus Centre, PO Box 2283, Road Town, Tortola VG 1110, British Virgin Islands.</li>
                    <li><strong>How to contact us</strong>. You can contact us by writing to us at <a href="mailto:info@temtum.com">info@temtum.com</a>.</li>
                    <li><strong>How we may contact you</strong>. If we have to contact you we will do so by telephone, by writing to you at the email address or postal address you provided to us as part of your purchase, or by message provided through your online account with us.</li>
                    <li><strong>"Writing" includes emails</strong>. When we use the words "writing" or "written" in these Terms, this includes emails.</li>
                  </ol>
                </li>

                <li>
                  <h4>Our contract with you</h4>

                  <ol className="nested-counters list-inside">
                    <li><strong>How we will affirm your Wallet set-up. </strong>Our acceptance of your attempt to set-up your Temtum Wallet will take place when you are notified of successful and satisfactory completion of KYC (as defined below), or otherwise when you are notified of the Keypair (as defined below) for the Wallet (although, unless and until kyc is successfully completed, the Wallet may not enable you to transfer Temtum and may have other limits), at which point a contract will come into existence between you and us in respect of your access and use of the Wallet.</li>
                    <li><strong>How we will accept your request to purchase</strong>. If you are purchasing via the Website, our acceptance of your request to purchase Temtum will take place when we email you to accept it, at which point a contract will come into existence between you and us in respect of your purchase of Temtum. For other direct sales, our acceptance of your request to purchase Temtum will take place when we sign a contract with you. If you are purchasing via an Exchange (as defined below), acceptance of requests to purchase will take place in accordance with the terms of the Exchange.</li>
                    <li><strong>If we cannot accept your request to set up a Wallet or purchase Temtum</strong>. If we are unable to accept your request to set up a Wallet and/or purchase Temtum, you will be notified of this and, if applicable, will not charge you for the Temtum purchased. This might be because, for some reason, we are unable to obtain satisfactory results from KYC checks or that a cap on sales is reached (see 8.5 below) prior to us transferring Temtum to you.</li>
                  </ol>
                </li>

                <li>
                  <h4>Purpose and use of temtum</h4>

                  <ol className="nested-counters list-inside">
                    <li>Temtum is a cryptocurrency, issued by us, for use by Temtum Wallet holders within the Temtum network. Temtum's sole functionality is that it can be transferred between Temtum Wallet holders through the use of login details or a Keypair (as defined below). For a more detailed explanation of the features of Temtum please see Schedule 1 (Useful Information) below, our Website and our White Paper.</li>
                    <li>Temtum may be purchased by you directly from us or via an Exchange (as defined below) as set out in clause 8.4.</li>
                    <li>You will be able to transfer Temtum from your Wallet to another Temtum Wallet holder immediately following delivery by us of your purchased Temtum, unless we expressly agree otherwise with you.</li>
                    <li><strong>Temtum charges.</strong> The purchase, sale or holding of Temtum will be subject to the fees, costs and charges which will be set out in our White Paper and/or Website from time to time, and which may be deducted by us from your Wallet.</li>
                  </ol>
                </li>

                <li>
                  <h4>Setting up a wallet</h4>

                  <ol className="nested-counters list-inside">
                    <li>
                      <strong>How to get access to Temtum. </strong>In order to purchase Temtum from us or otherwise use Temtum you will need to either:

                      <ol className="nested-counters">
                        <li>
                          <strong>Set up a Wallet with us:</strong>

                          <ol type="a">
                            <li>Visit our Website and set up an account with us (for login purposes). This is your Wallet and it will be used to hold, transfer and receive Temtum.</li>
                            <li>In order to initiate the Wallet set-up process, you will need to provide us with an email address, phone number and create associated login details (a username and password).</li>
                            <li>To complete your Wallet setup, to enable you to subsequently purchase, transfer or receive Temtum, you will have to successfully pass identity verification (<strong>KYC</strong>) checks that are part of the Wallet registration process. If you do not pass these checks, you will not be able to use a Wallet or purchase Temtum directly from us.</li>
                            <li>Successfully setting up your Wallet will register you on the Temtum network through a unique private key and public key (identification number) linked to your Wallet (<strong>Keypair</strong>), which you may request from us if you wish. Your Wallet login details or Keypair will allow you to send and receive Temtum to or from other users on the network that have also set up a Wallet. It is critical that you take adequate security measures (including those set out in clause 10.2 below) to protect your login details and, if requested by you, your Keypair to prevent unauthorised access to your Temtum.</li>
                            <li>In certain other circumstances:</li>
                            <li>we may agree to assist you by setting up a Wallet for you, in which case we will provide you with the required login details and, if requested by you, your Keypair following satisfactory completion of KYC checks and successful completion of Wallet set up.</li>
                            <li>please be aware that, although your Wallet may be set-up and you may have access to your Wallet prior to Launch:</li>
                            <li>you will only be allocated pre-purchased Temtum on Launch; and</li>
                            <li>your Wallet will only have functionality to transfer or receive Temtum to or from other Wallet holders from Launch.</li>
                            <li>If you do not set up a Wallet, you will not be able to purchase, transfer or receive Temtum without using an Exchange (as defined below).</li>
                            <li>For more detail on the permitted use of our Website and your Wallet, please refer to the terms of use which can be found on our Website and to clause 7 below.</li>
                          </ol>
                        </li>

                        <li>
                          <strong>Purchase Temtum via an exchange:</strong>
                          <ol type="a">
                            <li>You may also purchase Temtum via a cryptocurrency exchange (<strong>Exchange</strong>) that deals in Temtum, a list of which is to be found on our Website.</li>
                            <li>In such circumstances, you will need to register with the Exchange and go through KYC checks with the Exchange to set up a Wallet and you will be able to purchase and transfer Temtum via the Exchange. As a result, you will in addition be subject to the terms set by the relevant Exchange. Please refer to your terms with the relevant Exchange for more detail.</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li><strong>Wallet charges. </strong>The use or holding of a Wallet will be subject to the fees, costs and charges which will be set out in our White Paper and/or on our Website from time to time, and which may be deducted by us from your Wallet.</li>
                  </ol>
                </li>

                <li>
                  <h4>TEMTUM WALLET ACCESS</h4>

                  <ol className="nested-counters list-inside">
                    <li>As noted above, you are able to set up an online account on our Website known as a Wallet through which you can hold, transfer and receive Temtum. In order to set up a Wallet you will need to complete the steps at clause 6.1.1 above.</li>
                    <li>You are able to set up a Wallet with us, without having to purchase any associated Temtum. By way of example, you may set-up a Wallet merely to receive a transfer of Temtum from another Temtum Wallet holder.</li>
                    <li>Following Launch, we may also launch an App to enable you to access your Wallet (which if launched would be available via the Google Play Store and/or Apple App Store and/or via the links on our Website). Please note that you must set up a Wallet by completing the steps at clause 6.1.1 above before you will be able to use the App to access your Wallet. On the App you are able to transfer and view your Temtum.</li>
                    <li>
                      You should be aware that, although we take reasonable steps to keep it secure, your Wallet is subject to the threats and issues that arise for any online account (as touched on IN schedule 2). In particular, you should note that:
                      <ol className="nested-counters">
                        <li>we are unable to GIVE ANY SPECIFIC COMMITMENTS AS TO THE AVAILABILITY OF YOUR WALLET OR THE TEMTUM NETWORK;</li>
                        <li>WE CANNOT GUARANTEE THAT YOUR WALLET OR THE TEMTUM NETWORK WILL NOT BE SUBJECT TO INTERFEreNCE OR ATTACKS FROM HACKERS, OTHER MALICIOUS GROUPS OR OTHER ORGANISATIONS, FOR EXAMPLE malware attacks OR denial of service attacks;</li>
                        <li>WE CANNOT GUARANTEE THAT YOU WILL BE ABLE TO RECOVER ANY TEMTUM THAT YOU ERRONEOUSLY TRANSFER TO ANOTHER WALLET HOLDER OR THAT HAS BEEN TRANSFERRED WITHOUT YOUR AUTHORISATION; AND</li>
                        <li>We will not be liable to you if for any reason YOUR WALLET is unavailable at any time or for any period.</li>
                      </ol>
                    </li>

                  </ol>
                </li>
                <li>
                  <h4>PURCHASE OF TEMTUM</h4>

                  <ol className="nested-counters list-inside">
                    <li>If applicable, we agree to sell to you and you agree to purchase from us a specific amount of Temtum (subject to these Terms) at either: (i) the prices and exchange rates for conversion detailed by us on our Website for sales via the Website from time to time; (ii) the prices and exchange rates for conversion otherwise notified to you in relation to other direct sales from time to time; or (iii) the prices and exchange rates for conversion notified by an Exchange in relation to sales via such Exchange from time to time.</li>
                    <li>
                      <strong>How the direct purchase of Temtum works. </strong>If you have successfully set up your Wallet and passed our KYC checks, you may (in summary) purchase Temtum from us as follows:
                      <ol className="nested-counters">
                        <li>We will provide you, through your Wallet, with US dollar (or, at our discretion, another fiat currency or cryptocurrency) payment details. If you are not purchasing via the Website we may, on request, provide US dollar (or, at our discretion, another fiat currency or cryptocurrency) payment details to you directly.</li>
                        <li>You will transfer the relevant amount of US dollars (or, at our discretion, another fiat currency or cryptocurrency) for the particular amount of Temtum that you would like to purchase, by using the payment details referenced at clause 8.2.1 and in accordance with any other payment instructions we provide.</li>
                        <li>We will then transfer the Temtum that you have purchased to your Wallet within a reasonable period after we have received satisfactory confirmation of receipt of payment (or if later, on Launch).</li>
                      </ol>
                    </li>
                    <li>
                      <strong>Incorrect amount of Temtum transferred: </strong>If, in accordance with the prices and exchange rates for conversion detailed in clause 8.1 above, we transfer to you:

                      <ol className="nested-counters">
                        <li>less Temtum than you have paid for (or we fail to transfer to you any Temtum at all), please contact us through the details provided at clause 3.2 above. Once we can confirm and verify the further amount of Temtum that you are entitled to, we will (within a reasonable period) ensure that we transfer to you further Temtum that you are owed; or</li>
                        <li>more Temtum than you have paid for, please contact us through the details provided at clause 3.2 above. Once we can confirm and verify that the amount of Temtum that has been transferred to you is too great, we may (at our discretion) require that you transfer the excess Temtum to our Temtum Wallet.</li>
                      </ol>
                    </li>
                    <li><strong>Sales methods</strong>: We may at our discretion decide to sell any Temtum that may be available for sale by us in one or more sales, on terms decided by us from time to time. These sales may be, by way of example, in the following forms: (i) sales as notified on the Website; (ii) private sales to an interested party; or (iii) sales of Temtum via Exchanges.</li>
                    <li><strong>Once any cap on sales of Temtum is reached: </strong>If you attempt to purchase Temtum on our Website, we will reject your attempted purchase if sales of Temtum have reached any cap for such sales set out on our Website, and the funds that you have attempted to transfer to us will be returned to your fiat currency account or cryptocurrency wallet, as applicable.</li>
                  </ol>
                </li>
                <li>
                  <h4>PRICE AND PAYMENT</h4>

                  <ol className="nested-counters list-inside">
                    <li>You may pay for Temtum in US dollars (or, at our discretion, another fiat currency or cryptocurrency) or as set by an Exchange in relation to sales via such Exchange. The total price for purchase of Temtum, and the exchange rates for conversion are as detailed on our Website from time to time in relation to sales via the Website, or as otherwise notified to you in relation to other sales. Please refer to these to identify the price that you will need to pay for the amount of Temtum that you would like to purchase and when you will need to pay that price.</li>
                    <li>Please be aware the payments that you make are only for your purchase of Temtum and that the Wallet is provided by us free of charge.</li>
                    <li><strong>No right of set-off if you are a business customer</strong>. If you are a business customer you must pay all amounts due to us under these Terms in full without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as required by law).</li>
                  </ol>
                </li>
                <li>
                  <h4>WHAT YOU SHOULD BE AWARE OF</h4>

                  <ol className="nested-counters list-inside">
                    <li><strong>Potential Risks: </strong>PLEASE READ THIS CLAUSE 10 AND SCHEDULE 2 CAREFULLY BEFORE PURCHASING TEMTUM TO FAMILIARISE YOURSELF WITH THE POTENTIAL RISKS ASSOCIATED WITH THE PURCHASE, HOLDING AND USE OF TEMTUM.</li>
                    <li>
                      <strong>Security: </strong>You will need to take reasonable measures to keep your Wallet login details and Keypair (if requested by you) as secure as you can. These measures should include that you:
                      <ol className="nested-counters">
                        <li>do not share your login details or Keypair with any other individuals;</li>
                        <li>keep an accurate and safely stored record of your login details and Keypair. You may want to purchase security software to store these details;</li>
                        <li>ensuring that you select a robust password that cannot be easily guessed. An online password generator can be used to assist with this. We also recommend that you enable the two factor authentication that we provide with the Wallet for additional security; and</li>
                        <li>ensure that your devices have up to date and robust firewall and anti-virus software installed.</li>
                      </ol>

                      <p>If you do not take reasonable measures to keep your Wallet login details and Keypair secure, you may not be able to recover Temtum that has been transferred from your Wallet without your authorisation.</p>
                    </li>
                    <li><strong>Number of wallets</strong>: Unless we agree otherwise, you will only be able to hold one Wallet per individual or entity.</li>
                    <li><strong>Limited use</strong>: Temtum can only be used as a means of value transfer between holders of Temtum Wallets.</li>
                    <li><strong>Return on payment</strong>: If you transfer Temtum to another Wallet holder, this transfer will not result in any automatic transfer of value from your counterparty to you. You will need to ensure that you have separately arranged a means of receiving payment, or value, from the counterparty for your transfer (if this is anticipated by you).</li>
                    <li><strong>Refund, repair and replacement</strong>: You should be aware that purchases of Temtum will only be refunded, repaired or replaced as set out at clauses 12.2, 13.3, 15.2 and 18.2 and in accordance with your legal rights (as summarised at clause 17). Other than your rights as provIded by these terms and your legal rights to obtain a refund, repair or replacement (WHERE APPLICABLE), sales of Temtum are final and cannot be reversed.</li>
                    <li><strong>No associated rights. </strong>As stated at clause 1 (FOREWORD) to these Terms, YOU SHOULD BE AWARE THAT THE ONLY RIGHT ASSOCIATED WITH TEMTUM IS THAT IT IS A CRYPTOCURRENCY OR DIGITAL COIN THAT CAN BE TRANSFERRED BETWEEN WALLET HOLDERS. OWNERSHIP OF TEMTUM CARRIES NO FURTHER RIGHTS OR FUNCTIONALITY.</li>
                    <li><strong>No guaranteed value. </strong>Temtum, as a form of cryptocurrency, may fluctuate in value substantially over time. The value of Temtum will depend how widely it is used and adopted over time. There is a risk that Temtum may eventually have low or no value and that may result in you being unable to use Temtum.</li>
                    <li><strong>Further Temtum sale. </strong>An amount of Temtum will be issued on Launch as set out on our Website, some or all of which may be sold and/or allocated by us prior to and during Launch. We may sell and/or allocate the remaining Temtum after Launch at such price or prices and at such time or times as we may decide. Further Temtum may be issued from time to time where necessary or desirable.</li>
                    <li><strong>KYC</strong>: as stated above, as part of the Wallet sign-up process we will require that you complete KYC checks. In order to complete the KYC checks, we are dependent on one or more third party providers to verify the information that you provide. You should be aware that problems may arise with these KYC checks that our outside of our control, for example the information that you provide could be erroneously accepted and verified by our third party providers. As a result, WE CANNOT GUARANTEE THAT THE KYC CHECKS WILL BE COMPLETED WITHOUT ERROR. In such a scenario we may have to terminate access to your Wallet or cancel your purchase of Temtum in accordance with clause 15.1 below.</li>
                    <li><strong>The inability to exercise your right to change your mind if you are a consumer (Consumer Contracts Regulations 2013)</strong>. If you are a consumer then for most products bought online you have a legal right to change your mind within 14 days and receive a refund. In respect of the purchase of Temtum, you will not have this right to change your mind within 14 days. this is because the right does not apply to products for which the market price may vary. YOU ACKNOWLEDGE tHAT THIS RIGHT TO CANCEL DOES NOT APPLY BY CLICKING TO ACCEPT or otherwise signing THESE TERMS.</li>
                    <li><strong>Sale to certain countries</strong>: The sale or resale of Temtum may not be permitted in certain countries due to legal restrictions. YOU SHOULD ONLY PROCEED WITH THE PURCHASE OF TEMTUM AND/OR SET-UP OF A WALLET IF YOU ARE RESIDENT, LOCATED AND ESTABLISHED IN A JURISDICTION IN WHICH THE SALE AND PURCHASE OF CRYPTOCURRENCY IS LEGAL AND YOU ARE ABLE TO ACCESS YOUR WALLET FROM A JURISDICTION THAT DOES NOT PROHIBIT THE USE OF CRYPTOCURRENCY.</li>
                    <li><strong>Legal right</strong>: You should only purchase Temtum if you have the legal right and ability to do so, for example being 18 years of age or over. We are unable to accept Temtum purchase requests from individuals that are under 18 years of age.&nbsp;</li>
                    <li><strong>Tax: </strong>By purchasing Temtum, you warrant that you are registered for tax, in your country of residence, and will comply with your legal obligations in relation to paying tax in respect of the purchase, sale, holding or transfer of Temtum.</li>
                    <li><strong>Geoblocking</strong>: If you are based in specific jurisdictions in which the sale of Temtum carries additional risk or is illegal, you may find that you are unable to access the Website or Wallet or purchase Temtum as we may geoblock IP addresses based in such jurisdictions. If this applies to you, and you are interested in purchasing Temtum or wish to access your Wallet, please contact us (via the details at clause 3.2) and we will endeavour to advise on whether you are able to purchase Temtum or otherwise use a Wallet, and if so, to explain the methods by which you may be able to purchase Temtum or otherwise use a Wallet. PLEASE NOTE THAT THE JURISDICTIONS WHICH ARE GEOBLOCKED MAY VARY FROM TIME TO TIME. NO GUARANTEE IS GIVEN THAT JURISDICTIONS WHICH ARE CURRENTLY NOT GEOBLOCKED WILL CONTINUE TO BE FREE OF GEOBLOCKING IN THE FUTURE.</li>
                    <li><strong>If you are acting on behalf of a business</strong>: In addition to the above, you represent and warrant that you are authorised to accept these Terms on such entity's behalf and that such entity will be responsible for breach of these Terms by you or any other employee or agent of such entity.</li>

                  </ol>
                </li>
                <li>
                  <h4>Our rights to make changes</h4>

                  <ol className="nested-counters list-inside">
                    <li>
                      <strong>Changes to Temtum: </strong>We may make changes to the Temtum code or Wallet:

                      <ol className="nested-counters">
                        <li>to reflect changes in relevant laws and regulatory requirements or otherwise to ensure compliance with relevant laws or regulatory requirements.</li>
                        <li>to implement technical adjustments and/or improvements that will not materially adversely affect your Temtum or use of the Wallet.</li>
                        <li>to address a security threat, in which case we may suspend your access to your Wallet in accordance with clause 12.5 below.</li>
                      </ol>

                      <p>Following any such changes, we may update or require you to update your Wallet or Temtum.</p>
                    </li>
                    <li><strong>Changes to the Terms:</strong> Given the developing nature of the cryptocurrency industry and regulation of this relatively new sector, we may make changes we consider necessary or desirable to update or modify these Terms, which may include the establishment of policies affecting Temtum or your Wallet, by posting the new form of these Terms on our Website, which you should check before use. </li>
                  </ol>
                </li>
                <li>
                  <h4>Provision of temtum and wallet suspension</h4>

                  <ol className="nested-counters list-inside">
                    <li><strong>When we will provide Temtum</strong>. As noted above at 8.2.3, provided that we have received satisfactory confirmation of payment, we will transfer Temtum to you within a reasonable time of such confirmation of payment (or if later, on Launch).</li>
                    <li><strong>We are not responsible for delays outside our control</strong>. If our transfer of Temtum by us to you, or your access to Temtum in your Wallet, is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take reasonable steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event, but if there is a risk of substantial delay you may contact us to end the contract and, if applicable, receive a refund for any Temtum you have paid for but not received.</li>
                    <li><strong>When you own Temtum</strong>. You own the Temtum once we have received payment in full and we have transferred the Temtum to your Wallet.</li>
                    <li><strong>What will happen if you do not give required information to us</strong>. We may request certain information from you so that we can sell Temtum and/or provide a Wallet to you, for example, KYC documentation (which could include a copy of your passport ID page). We will ask for this information as part of the process of setting up a Wallet and/or purchasing Temtum. We may also ASK FOR KYC OR OTHER OR FURTHER information AND/OR UNDERTAKINGS from time to time THAT WE CONSIDER NECESSARY OR DESIRABLE, FOR EXAMPLE INFORMATION NEEDED to comply with relevant laws and regulatory requirements (which may include identification information), OR AGREEMENT TO COMPLY WITH JURISDICTION SPECIFIC REQUIREMENTS. We will contact you to ask for this information and/or undertakings. If you do not give us any of the information and/or undertakings we ask for within a reasonable time of us asking for it, or if you give us incomplete or incorrect information or do not comply with undertakings, we may either end the contract under clause 15.1.2 or make a charge of a reasonable sum to compensate us for any extra work that is required as a result. We will not be responsible for transferring Temtum to you late, or not transferring it at all, if this is caused by you not giving us the information and/or undertakings we need within a reasonable time of us asking for it.</li>
                    <li>
                      <strong>Reasons we may suspend your access to your Wallet</strong>. We may have to suspend access to your wallet in order to:

                      <ol className="nested-counters">
                        <li>deal with technical problems or make technical changes;</li>
                        <li>update the system to reflect changes in relevant laws and regulatory requirements or otherwise to ensure compliance with relevant laws or regulatory requirements;</li>
                        <li>resolve issues that have arisen that are outside of our control, for example a security breach (i.e. a hack);</li>
                        <li>make reasonable updates to the system to improve its functionality.</li>
                      </ol>
                    </li>
                    <li><strong>Your rights if we suspend the access to your Wallet</strong>. We will contact you in advance to tell you we will be suspending supply of the Wallet, unless the problem is urgent or an emergency, or we are not permitted to contact you by relevant laws or regulatory requirements. You may contact us to end your contract for the Wallet if we suspend it, or tell you we are going to suspend it, in each case for a period of more than 14 days.</li>
                    <li><strong>We may also not provide the Temtum if you do not pay</strong>. If you do not pay us for the Temtum when you are supposed to (see clause 8.2.2) we may cancel your purchase of such Temtum.</li>
                  </ol>
                </li>
                <li>
                  <h4>Your rights to end the contract</h4>

                  <ol className="nested-counters list-inside">
                    <li>
                      <strong>Your contract with us: </strong>You may have a contract with us: i) in respect of the sale of Temtum by us to you (<strong>Temtum Sale Contract</strong>); and/or ii) for provision of ongoing access to the Wallet (<strong>Wallet Access Contract</strong>). Your rights differ depending on which contract you wish to end, as follows:

                      <ol className="nested-counters">
                        <li>in respect of Temtum Sale Contract, you are only able to cancel pursuant to clause 13.2; and</li>
                        <li>in respect of Wallet Access Contract, you are able to terminate pursuant to clauses 13.4 and 13.5.</li>
                      </ol>
                    </li>
                    <li><strong>Ending the Temtum Sale Contract because of something we have done.</strong> If you have, in respect of the purchase of Temtum from us, a legal right to end the contract because of something we have done wrong, then on the date that we receive a written termination notice from you, the contract will end immediately and, if applicable, we will refund you in accordance with clause 13.3 below (and any applicable legal rights you may have).</li>
                    <li>If you end the Temtum Sale Contract in accordance with clause 13.2, we will refund any payments that you have made to us for which you have not received the relevant Temtum prior to termination.</li>
                    <li>
                      <strong>Ending the Wallet Access Contract because of something we have done or are going to do</strong>. If you are ending the Wallet Access Contract for a reason set out at 13.4.1 to 13.4.3 below the contract will end immediately and, if applicable, you should take the steps set out at 13.6 prior to termination. The reasons are:

                      <ol className="nested-counters">
                        <li>in accordance with clause 12.5 and/or 12.6, we have suspended supply of your Wallet for technical or security reasons, or notified you we are going to suspend them for technical or security reasons, in each case for a period of more than 14 days;</li>
                        <li>you are unable to access and use your Wallet for a consecutive 14 day period for a reason outside of our control; or</li>
                        <li>you have, in respect of the provision of the Wallet, a legal right to end the contract because of something we have done wrong.</li>
                      </ol>
                    </li>
                    <li><strong>Ending the Wallet Access Contract for Convenience. </strong>You may also terminate the Wallet Access Contract on seven (7) days' notice to us.</li>
                    <li>If you end the Wallet Access Contract for any reason, please ensure that you transfer any Temtum (that you wish to retain) from your Wallet (before it is closed) to another Wallet where it can be stored. If you fail to do this before closure of your Wallet, you may not be able to recover the Temtum that was held in your Wallet.</li>
                  </ol>
                </li>
                <li>
                  <h4>How to end the contract with us</h4>

                  <ol className="nested-counters list-inside">
                    <li><strong>Tell us you want to end the contract</strong>. To end the contract with us pursuant to clause 13.2 (for the Temtum Sale Contract) or 13.4 or 13.5 (for the Wallet Access Contract), please let us know by emailing us at <a href="mailto:info@temtum.com">info@temtum.com</a>. Please provide your name, home address, Wallet details and, where available, your phone number.</li>
                    <li><strong>How we will refund you</strong>. If you are entitled to a refund under these Terms we will refund you the price you paid for the Temtum, by the method you used for payment.</li>
                    <li><strong>When your refund will be made</strong>. We will make any refunds due to you as soon as possible after receiving your notice to end the contract (where a refund right applies).</li>
                  </ol>
                </li>
                <li>
                  <h4>Our rights to end the contract</h4>

                  <ol className="nested-counters list-inside">
                    <li>
                      <strong>We may end the Temtum Sale Contract or Wallet Access Contract</strong>. Without prejudice to our other rights, we may (at any time) by writing to you:

                      <ol className="nested-counters">
                        <li>
                          end the Temtum Sale Contract or refuse your request to purchase Temtum if:

                          <ol type="a">
                            <li>you do not make any payment to us when it is due;</li>
                            <li>you do not, within a reasonable time of us asking for it, provide us with information that is necessary for us to provide the Temtum;</li>
                            <li>issues have arisen that are outside of our control that we are unable to resolve, for example, KYC checks that are erroneously verified by our third party provider that should not have been approved; or</li>
                            <li>we are required by law or requested by any regulatory authority to do so.</li>
                          </ol>
                        </li>

                        <li>
                          end the Wallet Access Contract (and/or suspend access to the Wallet) if:

                          <ol type="a">
                            <li>you do not make any payment to us when it is due, or you misuse or abuse the Wallet in any way;</li>
                            <li>you do not, within a reasonable time of us asking for it, provide us with information and/or undertakings that we consider to be necessary or desirable for us to provide or continue to provide the Wallet, for example, provision of KYC documentation (including any further information we may need from time to time to comply with relevant laws and regulatory requirements) or agreement to comply with jurisdiction specific requirements, or any such information does not successfully meet our KYC checks from time to time, or any such undertakings are not complied with from time to time;</li>
                            <li>issues have arisen that are outside of our control that we are unable to resolve, for example, KYC checks that are erroneously verified by our third party provider that should not have been approved; or</li>
                            <li>we are required by law or requested by any regulatory authority to do so.</li>
                          </ol>
                        </li>
                      </ol>
                    </li>

                    <li><strong>You may have to compensate us if you break the contract</strong>. If we end the Temtum Sale Contract in the situations set out in clause 15.1.1 we may refund any money you have paid to us in advance for Temtum that we have not provided but we may deduct or charge you reasonable compensation for the net costs we will incur as a result of your breaking the contract.</li>
                    <li><strong>Consequences of Termination. </strong>The termination of the Temtum Sale Contract or Wallet Access Contract will not affect any rights or liabilities that have accrued prior to the date of termination.</li>
                  </ol>
                </li>
                <li>
                  <h4>If there is a problem</h4>

                  <p><strong>How to tell us about problems</strong>. If you have any questions or complaints about the Temtum or your Wallet, please write to us at <a href="mailto:info@temtum.com">info@temtum.com</a>.</p>
                </li>
                <li>
                  <h4>Your rights in respect of defective Temtum if you are a consumer</h4>

                  <ol className="nested-counters list-inside">
                    <li>If you are a consumer we are under a legal duty to supply Temtum that are in conformity with these Terms. Your minimum rights under consumer protection law will vary depending on which country's laws apply to you. By way of illustration, see the following text for a summary of the key legal rights in relation to the Temtum based on the UK/EU position. Nothing in these Terms will affect your legal rights.</li>
                  </ol>

                  <p><strong><em>This is a summary of the legal rights that you may have, depending on which country's mandatory consumer protection laws apply to you. The below information is based on UK law, which in turn is based on a consumer protection regime that is common across the EU. These are subject to certain exceptions.</em></strong></p>
                  <p><strong><em><u>Summary of your key legal rights</u></em></strong></p>
                  <p><strong><em>If your product is <u>digital content</u>, for example Temtum, the digital content must be as described, fit for purpose and of satisfactory quality:</em></strong></p>
                  <ol type="a">
                    <li><strong><em>If your digital content is faulty, you're entitled to a repair or a replacement.</em></strong></li>
                    <li><strong><em>If the fault can't be fixed, or if it hasn't been fixed within a reasonable time and without significant inconvenience, you can get some or all of your money back</em></strong></li>
                    <li><strong><em>If you can show the fault has damaged your device and we haven't used reasonable care and skill, you may be entitled to a repair or compensation.</em></strong></li>
                  </ol>
                </li>
                <li>
                  <h4>Your rights in respect of Temtum if you are a business</h4>

                  <ol className="nested-counters list-inside">
                    <li>If you are a business customer we warrant that on the date of entry into the relevant contract, the Temtum and Wallet provided shall conform in all material respects with their description. Aside from this warranty in clause 18.1, we do not provide you with any warranties in respect of Temtum or the Wallet and, to the fullest extent permitted by law, we exclude all other terms, conditions or warranties implied by statute, law or otherwise.</li>
                    <li>
                      Subject to clause 18.3, if:

                      <ol className="nested-counters">
                        <li>you give us notice in writing within a reasonable time of the date of entry into the relevant contract that the Temtum transferred by us to you, or the Wallet, does not comply with the warranty set out in clause 18.1;</li>
                        <li>we are given a reasonable opportunity of examining the Temtum or Wallet issues; and</li>
                        <li>you provide us with access to your Wallet,</li>
                      </ol>

                      <p>we shall, at our option, repair or replace the Temtum and/or Wallet in question, or refund the price that you paid to us for the defective Temtum in full.</p>
                    </li>
                    <li>
                      We will not be liable for failure of Temtum or a Wallet to comply with the warranty in clause 18.1 if:

                      <ol className="nested-counters">
                        <li>you make any further use of such Temtum or Wallet after giving a notice in accordance with clause 18.2.1;</li>
                        <li>the issue arises because you failed to follow our oral or written instructions as to the holding of or use of the Temtum or Wallet;</li>
                        <li>the issue arises as a result of us following any instructions provided by you;</li>
                        <li>you alter the Temtum or Wallet without our written consent; or</li>
                        <li>the issue arises as a result of your negligence or transfer of the Temtum to the incorrect recipient.</li>
                      </ol>
                    </li>
                    <li>Except as provided in this clause 18, we shall have no liability to you in respect of failure of Temtum or a Wallet to comply with the warranty set out in clause 18.1 or otherwise.</li>
                    <li>These Terms shall apply to any repaired or replacement Temtum supplied by us under clause 18.2.</li>
                  </ol>
                </li>
                <li>
                  <h4>Our responsibility for loss or damage suffered by you if you are a consumer</h4>

                  <ol className="nested-counters list-inside">
                    <li><strong>We are responsible to you for foreseeable loss and damage caused by us</strong>. If we fail to comply with these Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this contract or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you knew it might happen, for example, if you discussed it with us during the sales process.</li>
                    <li><strong>We do not exclude or limit in any way our liability to you where it would be unlawful to do so</strong>. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the products as summarised at clause 17.1<strong>.</strong></li>
                    <li><strong>When we are liable for damage caused by defective digital content</strong>. If defective digital content which we have supplied damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill we will either repair the damage or pay you compensation. However, we will not be liable for damage which you could have avoided by following our advice to apply an update offered to you free of charge or for damage which was caused by you failing to correctly follow our instructions for usage or to have in place the minimum system requirements advised by us.</li>
                    <li><strong>We are not liable for business losses</strong>. If you are a consumer we only supply the Temtum and Wallet to you for domestic and private use. If you use the Wallet or Temtum for any commercial, business or re-sale purpose our liability to you will be limited as set out in clause 20.</li>
                    <li><strong>Our maximum liability to you</strong>. As explained at clause 19.2 above, we do not exclude or limit in any way our liability to you where it would be unlawful to do so. For those losses that are not excluded and that are caused by us in connection with this contract, we will not be liable to you for more than 100% of the total sums paid by you to us for Temtum under these Terms.</li>
                  </ol>
                </li>
                <li>
                  <h4>Our responsibility for loss or damage suffered by you if you are a business</h4>

                  <ol className="nested-counters list-inside">
                    <li>
                      Nothing in these Terms shall limit or exclude our liability for:

                      <ol className="nested-counters">
                        <li>death or personal injury caused by our negligence, or the negligence of our employees, agents or subcontractors (as applicable);</li>
                        <li>fraud or fraudulent misrepresentation;</li>
                        <li>any matter in respect of which it would be unlawful for us to exclude or restrict liability.</li>
                      </ol>
                    </li>
                    <li>
                      Subject to clause 20.1:

                      <ol className="nested-counters">
                        <li>we shall not be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit, loss of Temtum, loss caused by inability to access your Wallet, loss of data or any indirect or consequential loss arising under or in connection with any contract between us; and</li>
                        <li>our total liability to you for all other losses arising under or in connection with the subject matter of these Terms or any contract between us, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall be limited to 100% of the total sums paid by you to us for Temtum pursuant to these Terms.&nbsp;</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h4>How we may use your personal information</h4>

                  <p><strong>How we will use your personal information</strong>. We will only use your personal information as set out in our <strong>Privacy Notice</strong> which can be found on our Website.</p>
                </li>
                <li>
                  <h4>Other important terms</h4>

                  <ol className="nested-counters list-inside">
                    <li><strong>We may transfer this agreement to someone else</strong>. We may transfer our rights and obligations under these Terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</li>
                    <li><strong>You need our consent to transfer your rights to someone else</strong>. Unless you are a consumer and a restriction on assignment would be unfair and unenforceable, you may only transfer your rights or your obligations under these Terms to another person if we agree to this in writing.</li>
                    <li><strong>Nobody else has any rights under this contract</strong>. This contract is between you and us. No other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person in order to end the contract or make any changes to these Terms.</li>
                    <li><strong>If a court finds part of this contract illegal, the rest will continue in force</strong>. Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</li>
                    <li><strong>Even if we delay in enforcing this contract, we can still enforce it later</strong>. If we do not insist immediately that you do anything you are required to do under these Terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</li>
                    <li><strong>Which laws apply to this contract and where you may bring legal proceedings if you are a consumer</strong>. If you are a consumer, these Terms are governed by English law and you can bring legal proceedings in respect of the products in the courts of England. However, please note that, despite the above choice of governing law, this will not affect the application of mandatory laws in the jurisdiction in which you are resident, such as consumer protection laws, and nothing in this clause is intended to contravene any rights you have to bring legal proceedings in the jurisdiction in which you are resident.</li>
                    <li><strong>Which laws apply to this contract and where you may bring legal proceedings if you are a business</strong>. If you are a business customer, any dispute or claim arising out of or in connection with a contract between us or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with English law and the courts of England shall have exclusive jurisdiction to settle any such dispute or claim.</li>
                    <li><strong>You may not bring class action. </strong>You agree that you may only bring claims against us alone, or in your individual capacity, and not as a claimant or member in any: (i) class action; or (ii) any other type of group litigation, arbitration or representative action. This clause does not apply to any of your legal rights in respect of class actions that cannot be lawfully excluded.</li>
                  </ol>
                </li>
              </ol>


              <h4>SCHEDULE 1</h4>
              <h4>USEFUL INFORMATION</h4>

              <p>We have set out below answers to some questions that may help you understand some of the key concepts included in our Terms above and give you in an introduction as to what Temtum is. For any further questions, please contact us through the details provided at clause 3.2 of the Terms.</p>

              <ol className="nested-counters list-inside">
                <li>
                  <h4>Who is Great Harbour Trading Limited?</h4>

                  <p>Great Harbour Trading Limited (which trades under the name Temtum, but is not referred to as Temtum here), is a limited company formed in the British Virgin Islands that has the rights (under licence from its affiliate Dragon Infosec Limited, registered in the UK) to use the proprietary blockchain protocol (on which the Temtum cryptocurrency has been developed) and sell Temtum to consumers and businesses. Great Harbour Trading Limited has responsibility for provision of the Temtum and the functionality of Temtum, and the ongoing provision of a Wallet to owners of Temtum.</p>
                </li>
                <li>
                  <h4>What is a cryptocurrency?</h4>

                  <p>A cryptocurrency or digital coin is designed to work as a store of value or medium of exchange that uses cryptography to secure its transactions and to verify the transfer of the coin. Cryptocurrencies operate independent of a central bank. The control of each cryptocurrency works through a blockchain; which is a network-wide transaction database, functioning as a distributed ledger.</p>
                  <p>Cryptocurrencies cannot be deposited in a bank.</p>
                </li>
                <li>
                  <h4>What is Temtum? What is its functionality?</h4>

                  <p>The functionality of Temtum is to act as a virtual currency which can be moved between Wallet holders. Other than acting as a store of value or medium of exchange that can be transferred between Wallet Holders, Temtum has no other discernible rights or additional functionality. By way of example, it does not act as a security (e.g. providing rights to dividends or assets) and does not provide digital access to any particular application or service.</p>
                  <p>Temtum will be distributed to the market by sales by Great Harbour Trading Limited over time.</p>
                </li>
                <li>
                  <h4>What are the main characteristics of Temtum?</h4>

                  <p>Please refer to clause 1, clause 10 and our White Paper.</p>
                </li>
                <li>
                  <h4>What is a Wallet? How can I move Temtum to or from my Wallet?</h4>

                  <p>A Wallet is, simply, an account protected by login details (a username and password) through which the purchased Temtum is held by the Wallet user. The Wallet login details or keypair can be used to transfer or receive Temtum.</p>
                  <p>A keypair is a pair of unique identification numbers, often referred to as a public key and a private key, that is generated by us, which links to your wallet and is used to identify the holder of the Temtum.</p>
                  <p>Please keep your login details and keypair (if requested by you) private and secure and only share them with trusted recipients.</p>
                </li>

                <li>
                  <h4>What technical protection/security measures are taken to attempt to ensure the security of Temtum and Wallets?</h4>

                  <p>Great Harbour Trading Limited utilises a number of security measures to attempt to ensure that your Wallet and Temtum remains secure. We have not provided detail of these measures, as this detail may itself draw attention from hackers or other malicious actors, and although we cannot guarantee that your wallet or the Temtum network will not be subject to interference or attacks from hackers or other malicious actors, we believe that these measures are reasonable and adequate security measures.</p>
                </li>

                <li>
                  <h4>Is there any specific hardware or software that I need to access a Wallet?</h4>

                  <p>No, you can access your Wallet through any device that can connect to the internet and run an up-to-date web browser.</p>
                </li>

                <li>
                  <h4>How much Temtum will be issued?</h4>

                  <p>An amount of Temtum will be issued on Launch as set out on our Website, some or all of which may be sold and/or allocated by us prior to and during Launch. We may sell and/or allocate the remaining Temtum after Launch at such price or prices and at such time or times as we may decide. Further Temtum may be issued from time to time where necessary or desirable.</p>
                </li>
              </ol>

              <h4>SCHEDULE 2</h4>
              <h4>POTENTIAL RISKS TO BE AWARE OF</h4>

              <p>In addition to those risks detailed in the main body of the Terms, we set out in some further detail below in relation to some additional risks that may arise in respect of Temtum. Prior to purchasing Temtum, please carefully consider the risks below.</p>

              <ol className="nested-counters list-inside">
                <li>
                  <h4>Availability</h4>

                  <p>We do not give any specific commitments as to the availability of your Wallet or the Temtum network.</p>
                </li>

                <li>
                  <h4>Security</h4>

                  <p>We cannot guarantee that your wallet or the Temtum network will not be subject to interference or attacks from hackers or other malicious actors. We will take reasonable and adequate security measures but hackers or other malicious groups or organisations may attempt to interfere with us or Temtum in a variety of ways, including malware attacks, denial of service attacks, consensus-based attacks, Sybil attacks, smurfing and spoofing. These attacks may be outside of our control but we will take steps to resolve them.</p>
                </li>

                <li>
                  <h4>Nature of cryptocurrencies</h4>

                  <p>Cryptocurrencies are extremely volatile and Temtum may suffer from such volatility. This means the value of Temtum could fluctuate, up or down, significantly. Temtum may be influenced by cryptocurrency market trends and Temtum value may be severely affected due to non-Temtum related events in the cryptocurrency markets such as a financial crash.</p>
                </li>

                <li>
                  <h4>Loss of Temtum</h4>

                  <p>The Wallet login details or Keypair (as defined and explained above) are necessary to control and transfer Temtum stored in your Wallet. Accordingly, a loss of your Wallet login details or associated Keypair may result in you being unable to use your Temtum.</p>
                  <p>Additionally, any third party that gains access to your Wallet login details or Keypair may be able to steal your Temtum. As stated above, please take steps to ensure the security of your Wallet login details and, if requested by you, your Keypair.</p>
                  <p>We cannot guarantee that you will be able to recover any Temtum that you erroneously transfer to another wallet holder or that has been transferred without your authorisation.</p>
                </li>

                <li>
                  <h4>Regulatory Risk</h4>

                  <p>The regulatory status of Temtum and blockchain technology is unclear or unsettled in many jurisdictions. It is difficult to predict how or whether regulatory agencies may apply existing regulation with respect to such technology and its applications. It is likewise difficult to predict how or whether governments or regulatory agencies may implement changes to law and regulation affecting Temtum. Please be aware that changes in laws or regulatory status or actions by governmental authorities could negatively impact the value of Temtum or even our ability to issue Temtum or to continue to provide the Wallet service.</p>
                </li>

                <li>
                  <h4>Tax</h4>

                  <p>The tax treatment of any purchase, sale, holding or transfer of Temtum is uncertain, and any such purchase, sale, holding or transfer may be or become subject to taxation (including sales taxes or VAT) on the value bought, sold, held or transferred in one or more jurisdictions. You should seek your own tax advice in connection with any purchase, sale, holding or transfer of Temtum.</p>
                </li>

                <li>
                  <h4>Currency Exchange Rates</h4>

                  <p>You may pay for Temtum with US dollars or, at our discretion, another fiat currency or cryptocurrency, but you will only be allocated Temtum based on the US dollar value received by us at the exchange rates for conversion specified in clause 8.1 unless we, in our discretion, agree otherwise with you. Depending on these exchange rates for conversion, you may be allocated less Temtum than you were expecting.</p>
                </li>

                <li>
                  <h4>Demand</h4>

                  <p>It is possible that Temtum will not be used by a large number of individuals, companies and other entities. Such a lack of use or interest could negatively impact the development of Temtum, including its value.</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</Layout> )

export default Terms
